import React from "react";
import Header from "../../../components/header";
import HeroBanner from "../../../components/HeroBanner";
import Layout from "../../../components/layout";
import ProductWithImage from "../../../components/ProductWithImage";
import WithProviders from "../../../components/WithProviders";

import SelfHelpTool from "../../images/self-help-tools.png";
import SelfHelpTool2 from "../../images/self-help-tools@2x.png";
import SelfHelpTool3 from "../../images/self-help-tools@3x.png";

import CustomerWorkflowManagement from "../../images/customer-workflow-management.png";
import CustomerWorkflowManagement2 from "../../images/customer-workflow-management@2x.png";
import CustomerWorkflowManagement3 from "../../images/customer-workflow-management@3x.png";

import CustomerNotification from "../../images/customer-notification.png";
import CustomerNotification2 from "../../images/customer-notification@2x.png";
import CustomerNotification3 from "../../images/customer-notification@3x.png";

import CustomerDisputeManagement from "../../images/customer-dispute-management.png";
import CustomerDisputeManagement2 from "../../images/customer-dispute-management@2x.png";
import CustomerDisputeManagement3 from "../../images/customer-dispute-management@3x.png";
import ListView from "../../../components/ListView";

import "./style.css";
import { Message } from "../../../lib/getMessage";
const SelfHelpDesc = (isDirectionRTL) => (
  <ListView
    lists={[
      <Message dictKey="products.customersupport.selfhelp.item1" />,
      <Message dictKey="products.customersupport.selfhelp.item2" />,
      <Message dictKey="products.customersupport.selfhelp.item3" />,
      <Message dictKey="products.customersupport.selfhelp.item4" />,
    ]}
    isDirectionRTL={isDirectionRTL}
  />
);
const WorkflowManagementDesc = (isDirectionRTL) => (
  <ListView
    lists={[
      <Message dictKey="products.customersupport.workflowmanagement.item1" />,
      <Message dictKey="products.customersupport.workflowmanagement.item2" />,
      <Message dictKey="products.customersupport.workflowmanagement.item3" />,
    ]}
    isDirectionRTL={isDirectionRTL}
  />
);
const CustomerSupport = (props) => {
  const { language, updateLanguage, direction } = props;
  const isDirectionRTL = direction === "rtl";
  return (
    <Layout
      direction={direction}
      showFooterForm
      language={language}
      location={props.location}
    >
      <div>
        <Header
          current="product"
          language={language}
          updateLanguage={updateLanguage}
          direction={direction}
        />
        <div className="pr-cust-sprt">
          <HeroBanner
            title={<Message dictKey="products.customersupport.title" />}
            className={`customer-support-banner ${
              isDirectionRTL && "customer-support-banner-rtl"
            }`}
            isDirectionRTL={isDirectionRTL}
          />
          <section className="self-service-wrp">
            <ProductWithImage
              name="self-help"
              className="self-help"
              heading={
                <Message dictKey="products.customersupport.selfhelp.heading" />
              }
              description={[
                {
                  type: "component",
                  val: SelfHelpDesc(isDirectionRTL),
                  id: 1,
                },
              ]}
              image={{
                normal: SelfHelpTool,
                xx: SelfHelpTool2,
                xxx: SelfHelpTool3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
          </section>
          <section className="support-staff-tool">
            <div className="header">
              <h2>
                <Message dictKey="products.customersupport.supportstaff.heading" />
              </h2>
            </div>
            <ProductWithImage
              name="wrkflow-management"
              className="wrkflow-management"
              heading={
                <Message dictKey="products.customersupport.workflowmanagement.heading" />
              }
              description={[
                {
                  type: "component",
                  val: WorkflowManagementDesc(isDirectionRTL),
                  id: 1,
                },
              ]}
              image={{
                normal: CustomerWorkflowManagement,
                xx: CustomerWorkflowManagement2,
                xxx: CustomerWorkflowManagement3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
            <ProductWithImage
              name="dispute-management"
              className="dispute-management"
              heading={
                <Message dictKey="products.customersupport.disputemanagement.heading" />
              }
              description={[
                {
                  type: "p",
                  val: (
                    <Message dictKey="products.customersupport.disputemanagement.description" />
                  ),
                  id: 1,
                },
              ]}
              image={{
                normal: CustomerDisputeManagement,
                xx: CustomerDisputeManagement2,
                xxx: CustomerDisputeManagement3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
            <ProductWithImage
              name="notifications"
              className="notifications"
              heading={
                <Message dictKey="products.customersupport.notifications.heading" />
              }
              description={[
                {
                  type: "p",
                  val: (
                    <Message dictKey="products.customersupport.notifications.description" />
                  ),
                  id: 1,
                },
              ]}
              image={{
                normal: CustomerNotification,
                xx: CustomerNotification2,
                xxx: CustomerNotification3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default WithProviders(CustomerSupport);
